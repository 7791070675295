import ukConfig from "./regions/uk.json";
import usaConfig from "./regions/usa.json";
import transportStatementConfig from "./reports/transportStatement.json";
import transportationImpactStudyConfig from "./reports/transportationImpactAssessment.json";

export const regions = Object.freeze({
    USA: "USA",
    UK: "UK",
});

export const reports = Object.freeze({
    TRANSPORT_STATEMENT: "Transport Statement",
    TRANSPORTATION_IMPACT_STUDY: "Transportation Impact Study",
});

export const regionalConfig = Object.freeze({
    [regions.USA]: usaConfig,
    [regions.UK]: ukConfig,
});

export const reportConfig = Object.freeze({
    [reports.TRANSPORT_STATEMENT]: transportStatementConfig,
    [reports.TRANSPORTATION_IMPACT_STUDY]: transportationImpactStudyConfig,
});
