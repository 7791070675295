import { fragment } from "xmlbuilder2";
import sampleBusServicesTableXML from "@/assets/xml/sampleBusServicesTable.xml?raw";

export const generateBusTableSectionHTML = (busTimetables) => {
    let html = `<p class="normal_text">The closest bus stops to the site and their timetables are summarised in Table 3.2 below.</p>
    <h3 class="figure_heading">Table 3.2 Bus Timetable Summary</h3>`;
    html = html + generateBusTableHTML(busTimetables);
    html = html + `<p class="source">Source: Bus Open Data Service (BODS), Department for Transport 2024</p>`;
    return html;
};

export const generateBusServicesTableAsList = (busTimetables) => {
    if (busTimetables) {
        // Filter only "included" stops based on checkboxes
        busTimetables = busTimetables.filter((stop) => stop.included);

        // Loop over services and calculate string values for each cell
        for (let i = 0; i < busTimetables.length; i++) {
            for (let tr = 0; tr < busTimetables[i].services.length; tr++) {
                // Generate weekday frequency text
                busTimetables[i].services[tr].weekday_frequency_text = generateBusFrequencyText({
                    frequency: busTimetables[i].services[tr].peak_weekday_frequency,
                    totalBuses: busTimetables[i].services[tr].total_buses_weekday,
                });

                // Generate weekday hours of operation text
                busTimetables[i].services[tr].weekday_hours_of_operation_text = generateHoursOfOperationText({
                    totalBuses: busTimetables[i].services[tr].total_buses_weekday,
                    firstService: busTimetables[i].services[tr].first_service_weekday,
                    lastService: busTimetables[i].services[tr].last_service_weekday,
                });

                // Generate weekend frequency text
                let saturday_frequency_text = generateBusFrequencyText({
                    frequency: busTimetables[i].services[tr].saturday_frequency,
                    totalBuses: busTimetables[i].services[tr].total_buses_saturday,
                });
                let sunday_frequency_text = generateBusFrequencyText({
                    frequency: busTimetables[i].services[tr].sunday_frequency,
                    totalBuses: busTimetables[i].services[tr].total_buses_sunday,
                });
                busTimetables[i].services[tr].weekend_frequency_text = generateWeekendFrequencyOrHoursOfServiceText({
                    saturday_text: saturday_frequency_text,
                    sunday_text: sunday_frequency_text,
                });

                // Generate weekend hours of operation text
                let saturday_hours_of_operation_text = generateHoursOfOperationText({
                    totalBuses: busTimetables[i].services[tr].total_buses_saturday,
                    firstService: busTimetables[i].services[tr].first_service_saturday,
                    lastService: busTimetables[i].services[tr].last_service_saturday,
                });
                let sunday_hours_of_operation_text = generateHoursOfOperationText({
                    totalBuses: busTimetables[i].services[tr].total_buses_sunday,
                    firstService: busTimetables[i].services[tr].first_service_sunday,
                    lastService: busTimetables[i].services[tr].last_service_sunday,
                });

                busTimetables[i].services[tr].weekend_hours_of_operation_text =
                    generateWeekendFrequencyOrHoursOfServiceText({
                        saturday_text: saturday_hours_of_operation_text,
                        sunday_text: sunday_hours_of_operation_text,
                    });
            }
        }
    }
    return busTimetables;
};

const generateBusFrequencyText = ({ frequency, totalBuses }) => {
    if (Math.round(frequency) >= 1) {
        return Math.round(frequency) + " per hour";
    }

    if (totalBuses) {
        return totalBuses + " per day";
    }

    return "No service";
};

const generateWeekendFrequencyOrHoursOfServiceText = ({ saturday_text, sunday_text }) => {
    // If no service on Saturday & Sunday, simply "No Service".
    // Else, give details for each day
    if (saturday_text == "No service" && sunday_text == "No service") {
        return "No service";
    } else {
        return "Sat: " + saturday_text + "\n" + "Sun: " + sunday_text;
    }
};

const generateHoursOfOperationText = ({ totalBuses, firstService, lastService }) => {
    if (!totalBuses) {
        return "No service";
    } else {
        // If last service >=24:00:00, subtract 24h
        let lastServiceHour = parseInt(lastService.slice(0, 2));
        if (lastServiceHour >= 24) {
            lastServiceHour = lastServiceHour - 24;
            lastService = lastServiceHour.toString().padStart(2, "0") + lastService.slice(2);
        }

        if (firstService == lastService) {
            return firstService.slice(0, 5);
        } else {
            return firstService.slice(0, 5) + " - " + lastService.slice(0, 5);
        }
    }
};

export const generateBusTableHTML = (busTimetables) => {
    let html = "";
    busTimetables = generateBusServicesTableAsList(busTimetables);

    const newTable = document.createElement("table");
    newTable.classList.add("bus_stops_table");

    // Create header rows
    let thead = document.createElement("thead");
    newTable.appendChild(thead);
    let row = thead.insertRow(0);
    let cell;

    cell = document.createElement("th");
    cell.innerText = "Bus Stop";
    cell.setAttribute("rowspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Distance";
    cell.setAttribute("rowspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Service";
    cell.setAttribute("rowspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Operator";
    cell.setAttribute("rowspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Route";
    cell.setAttribute("rowspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Weekday";
    cell.setAttribute("colspan", 2);
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Weekend";
    cell.setAttribute("colspan", 2);
    row.appendChild(cell);

    row = newTable.insertRow(-1);

    cell = document.createElement("th");
    cell.innerText = "Peak Frequency";
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Hours of Operation";
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Frequency";
    row.appendChild(cell);

    cell = document.createElement("th");
    cell.innerText = "Hours of Operation";
    row.appendChild(cell);

    // Data rows
    if (busTimetables) {
        let tbody;
        for (let i = 0; i < busTimetables.length; i++) {
            tbody = document.createElement("tbody");
            newTable.appendChild(tbody);

            // Add rows
            for (let tr = 0; tr < busTimetables[i].services.length; tr++) {
                row = tbody.insertRow(-1);
                let td;

                // Only insert stop columns for first row of each stop
                if (tr == 0) {
                    // Stop name
                    td = row.insertCell(-1);
                    td.classList.add("stop_name");
                    td.innerText = busTimetables[i].name;
                    td.setAttribute("rowspan", busTimetables[i].services.length);

                    // Distance
                    td = row.insertCell(-1);
                    td.classList.add("distance");
                    td.innerText = Math.round(busTimetables[i].distance) + "m";
                    td.setAttribute("rowspan", busTimetables[i].services.length);
                }

                // Service name
                td = row.insertCell(-1);
                td.classList.add("service_name");
                td.innerText = busTimetables[i].services[tr].route_short_name;

                // Operator name
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].operator_name;

                // Route description
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].route_description;

                // Weekday peak Freq
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].weekday_frequency_text;

                // Weekday hours of operation
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].weekday_hours_of_operation_text;

                // Weekend freq
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].weekend_frequency_text;

                // Weekend hours of operation
                td = row.insertCell(-1);
                td.innerText = busTimetables[i].services[tr].weekend_hours_of_operation_text;
            }
        }
        html = html + newTable.outerHTML;
        newTable.remove();
    }
    return html;
};

export const generateBusTableXML = (busTimetables) => {
    if (!busTimetables) {
        return sampleBusServicesTableXML;
    }

    busTimetables = generateBusServicesTableAsList(busTimetables);

    const busServicesTable = {
        bus_services_table: {
            service: busTimetables.flatMap((stop) =>
                stop.services.map((service) => ({
                    bus_stop: stop.name,
                    distance: `${Math.round(stop.distance)}m`,
                    service_number: service.route_short_name,
                    operator: service.operator_name,
                    route: service.route_description,
                    weekday_peak_frequency: service.weekday_frequency_text,
                    weekday_hours_of_operation: service.weekday_hours_of_operation_text,
                    weekend_peak_frequency: service.weekend_frequency_text,
                    weekend_hours_of_operation: service.weekend_hours_of_operation_text,
                }))
            ),
        },
    };

    return fragment(busServicesTable).end({ prettyPrint: true });
};
