<template>
    <SchemeflowMap
        mapKey="highways_map"
        isochrone-profile="select"
        :legendConfigMasterList="userStore.thisRegionConfig.highwaysMapConfig.legend"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { useFormDataStore } from "@/stores/FormDataStore";
import { onMounted, onUnmounted } from "vue";
import { useUserStore } from "@/stores/UserStore";

const store = useFormDataStore();
const userStore = useUserStore();

onMounted(async () => {
    setTimeout(() => {
        store.maps.highways_map.getMap().once("rendercomplete", () => (store.mapsReadyForExport.highways_map = true));
        store.maps.highways_map.getMap().renderSync();
    }, 2500);
});

onUnmounted(() => {
    store.mapsReadyForExport.highways_map = false;
});
</script>
