/**
 * Escapes special characters in a string to prevent issues with XML parsing.
 * Converts ampersands (&), less-than (<), and greater-than (>) symbols into
 * their corresponding XML entities. Should only be used for the string content
 * of XML elements, not XML sub-structures (where the tags shouldn't be escaped).
 *
 * @param {string} str - The string to be escaped.
 * @returns {string} The escaped string with XML entities.
 */
export const escapeXML = (str) => {
    // If null or undefined, return as-is
    if (str === null || str === undefined) {
        return str;
    }
    // Cast to a string if it's not already
    str = str.toString();

    // Replace bad characters
    return str.replaceAll("&", "&amp;").replaceAll("<", "&lt;").replaceAll(">", "&gt;");
};
