<template>
    <h2 class="font-bold text-lg mb-2">Inputs</h2>
    <slot name="form"></slot>

    <hr class="my-4" />

    <TweakerButtons
        :isGenerating="isGenerating"
        :handleGenerateSummary="handleGenerateSummary"
        :handleTweakSummary="handleTweakSummary"
        :summaryKey="props.summaryKey"
    ></TweakerButtons>
</template>

<script setup>
import { ref, inject } from "vue";
import { useFormDataStore } from "@/stores/FormDataStore";
import { updateXmlCustomPart } from "@/word/updateXmlCustomPart";
import TweakerButtons from "./Buttons/TweakerButtons.vue";
import { escapeXML } from "@/word/xml";

const axios = inject("axios");
const runningInOffice = inject("appIsRunningInOffice");

const store = useFormDataStore();
const posthog = inject("posthog");

const props = defineProps({
    generateSummaryPath: String,
    tweakSummaryPath: String,
    summaryKey: String,
    fields: {
        type: Array,
        default() {
            return [];
        },
    },
});

const isGenerating = ref(false);

const handleGenerateSummary = function () {
    // Set loading state to true
    isGenerating.value = true;

    const params = Object.fromEntries(
        props.fields.filter((key) => key in store.formData).map((key) => [key, store.formData[key]])
    );

    // Send a POST request with the payload
    axios
        .post(props.generateSummaryPath, params)
        .then((response) => {
            // Update summary with response
            store.formData[props.summaryKey] = response.data;
            if (runningInOffice.value) {
                updateXmlCustomPart(props.summaryKey, escapeXML(response.data));
            }
        })
        .catch((error) => {
            console.error("Error sending POST request:", error);
        })
        .finally(() => {
            // Set loading state to false when the response is received
            isGenerating.value = false;
        });
};

const handleTweakSummary = function (tweak_feedback) {
    // Set loading state to true
    isGenerating.value = true;

    const params = Object.fromEntries(
        props.fields.filter((key) => key in store.formData).map((key) => [key, store.formData[key]])
    );

    const data = {
        inputs: { ...params },
        summary: store.formData[props.summaryKey],
        feedback: tweak_feedback,
    };

    // Send a POST request with the payload
    axios
        .post(props.tweakSummaryPath, data)
        .then((response) => {
            // Update summary with response
            store.formData[props.summaryKey] = response.data;
            if (runningInOffice.value) {
                updateXmlCustomPart(props.summaryKey, escapeXML(response.data));
            }
            posthog.capture("AI summary tweaked with feedback", {
                summary_type: props.summaryKey,
                feedback: tweak_feedback,
            });
        })
        .catch((error) => {
            console.error("Error sending POST request:", error);
        })
        .finally(() => {
            // Set loading state to false when the response is received
            isGenerating.value = false;
        });
};
</script>
