<script setup>
/* global Office */
import { RouterView } from "vue-router";
import { useUserStore } from "@/stores/UserStore";
import router from "./router";
import { inject, nextTick } from "vue";

const configCatClient = inject("configCatClient");
const store = useUserStore();
const posthog = inject("posthog");
const runningInOffice = inject("appIsRunningInOffice");

// Router redirect logic
router.beforeEach(async (to, from) => {
    const userIsLoggedIn = await store.checkIfUserIsLoggedIn();

    switch (to.meta.auth) {
        case "any":
            break;

        case "authenticated":
            if (!userIsLoggedIn) {
                return { name: "login" };
            }
            if (to.meta.featureFlag) {
                const flagValue = await configCatClient.getValueAsync(to.meta.featureFlag, false, store.configCatUser);
                if (!flagValue) {
                    return { name: "portal" };
                }
            }
            // Check if user has access to route based on tier permissions.
            if (to.meta.checkTier && !store.moduleAvailable(to.name)) {
                return from;
            }

            // If runningInOffice is not yet set, await Office.onReady(), at
            // which point appIsRunningInOffice will be appropriately set
            // Without this check, router guard will assume app is not running
            // in office prematurely.
            if (runningInOffice.value == null) {
                await Office.onReady();
            }

            if (runningInOffice.value && !store.backendUserConfig.word_access) {
                return { name: "no_word_access" };
            }
            break;

        case "unauthenticated":
            if (userIsLoggedIn) {
                return from;
            }
            break;

        // Bounce away from "No word access" page if user is running in browser
        // or if they do have Word access
        case "noWordAccess":
            if (!runningInOffice.value || store.backendUserConfig.word_access) {
                return { name: "portal" };
            }
            break;

        default:
            break;
    }
});

// Capture pageviews in posthog
router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            posthog.capture("$pageview", { path: to.fullPath });
        });
    }
});

// Fix for errors generated when attempting to load file using old hash in name
// after a rebuild & deploy
// See https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
router.onError((error, to) => {
    if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("Importing a module script failed")
    ) {
        window.location = to.fullPath;
    }
});
</script>

<template>
    <RouterView />
</template>
