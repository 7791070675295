import { defaults } from 'ol/interaction/defaults'
import View from 'ol/View'
import Map from 'ol/Map'
import Control from 'ol/control/Control'
import Attribution from 'ol/control/Attribution'
import { Extent } from 'ol/extent'
import { Raster, Vector as VectorSource, Google } from 'ol/source'
import { Layer, Tile as TileLayer, VectorImage as VectorLayer, WebGLTile as WebGLTileLayer } from 'ol/layer'
import BaseLayer from 'ol/layer/Base.js'
import ImageLayer from 'ol/layer/Image'
import Collection from 'ol/Collection.js'
import { Icon, Style, Fill, Stroke } from 'ol/style'
import RegularShape from 'ol/style/RegularShape'
import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM.js'
import { Projection, transformExtent, useGeographic } from 'ol/proj'
import GeoJSON from 'ol/format/GeoJSON'
import Select from 'ol/interaction/Select'
import Modify from 'ol/interaction/Modify'
import Rotate from 'ol/control/Rotate.js'
import ScaleLine, { Units } from 'ol/control/ScaleLine.js'
import { toBlob, toPng } from 'html-to-image'
import { bbox } from 'ol/loadingstrategy'
import convert from 'color-convert'
import SchemeflowLegend from '@/maps/sflegend'
import { iconFromConfig } from '@/maps/configIcons'

// Import map styles
import { amenitiesStyles, isochroneColors, redlineStyle, siteLocationPointStyle, OS_LIGHT_MAP_TILES_URL, OS_MAP_ROAD_TILES_URL, OS_OUTDOOR_MAP_TILES_URL, SATELLITE_MAP_TILES_URL, accidentMarker } from '@/maps/constants'
import LayerGroup from 'ol/layer/Group'
import { Feature } from 'ol'
import { Geometry, Point } from 'ol/geom'

import { useEventBus } from '@vueuse/core'

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

// Create the operation function
const colorFilterOperation = (pixels: Array<Array<number>>, data: any) => {
  let pixel = pixels[0]
  if (data.grayscaleFilter) {
    const grayscalePixelValue = 0.2627 * pixels[0][0] + 0.678 * pixels[0][1] + 0.0593 * pixels[0][2]
    pixel[0] = grayscalePixelValue
    pixel[1] = grayscalePixelValue
    pixel[2] = grayscalePixelValue
  }

  // data.alpha is a value between 0 (transparent) and 255 (opaque)
  // If opacity is set and is less than 255
  if (data.alpha && data.alpha < 255) {
    // calculate RGB pixel value from alpha value (assuming white background)
    for (let i = 0; i < 3; i++) {
      pixel[i] = Math.round(pixel[i] * (data.alpha / 255) + 255 * (1 - data.alpha / 255))
    }
  }
  return pixel
}

const osmOverpassBboxLoader = (options: {
  extent: Extent;
  projection: Projection,
  osmQuery: Array<String>,
  vectorSource: VectorSource,
}) => {
  const [minlon, minlat, maxlon, maxlat] = transformExtent(options.extent, options.projection, 'EPSG:4326');
  const url = 'https://overpass-api.de/api/interpreter';

  // Build union queries by combining multiple elements of query array together.
  // Intersection queries can be constructed by giving multiple tags in a single element
  let queryStatements = '('
  options.osmQuery.forEach((queryElement) => {
    queryStatements += `nw${queryElement}(${minlat},${minlon},${maxlat},${maxlon});`
  })
  queryStatements += ');'

  const query = `
    [out:json];
    ${queryStatements}
    out center;
  `;

  fetch(url, {
    method: 'POST',
    body: new URLSearchParams({ data: query }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => response.json())
  .then(data => {
    const features = data.elements.map(element => new Feature({
      geometry: new Point([
        element.type == "node" ? element.lon : element.center.lon,
        element.type == "node" ? element.lat : element.center.lat
      ])
    }));
    options.vectorSource.addFeatures(features);
    return features
  })
  .catch(error => console.error('Error fetching data from OpenStreetMap API:', error));
}

class GoogleLogoControl extends Control {
  constructor() {
    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.style.bottom = '5px';
    element.style.left = '5px';
    element.src =
      'https://developers.google.com/static/maps/documentation/images/google_on_white.png';
    super({
      element: element,
    });
  }
}

export default class SchemeflowMap {
  private zoom: number
  private longitude: number
  private latitude: number
  private map: Map
  private baseMapLayers: LayerGroup
  private overlayLayers: LayerGroup
  private isochroneSource: VectorSource
  public isochroneOriginSource: VectorSource
  public busStopsSource: VectorSource
  public busStopsLayer: VectorLayer<VectorSource>
  private googleSource: Google
  private railStationsSource: VectorSource
  private supermarketsSource: VectorSource
  private primarySchoolsSource: VectorSource
  private secondarySchoolsSource: VectorSource
  private pharmaciesSource: VectorSource
  private postOfficesSource: VectorSource
  private chargePointsSource: VectorSource
  private ferryPortsSource: VectorSource
  private metroStopsSource: VectorSource
  private osmBusStopsSource: VectorSource
  private osmRailStationsSource: VectorSource
  private osmMetroStationsSource: VectorSource
  private osmLightRailStationsSource: VectorSource
  private osmTramStopsSource: VectorSource
  private osmChargePointsSource: VectorSource
  private osmFerryPortsSource: VectorSource
  private osmRestaurantsSource: VectorSource
  private osmBarsAndCafesSource: VectorSource
  private osmHospitalsSource: VectorSource
  private osmHealthClinicsSource: VectorSource
  private osmKindergartenSource: VectorSource
  private osmSchoolsSource: VectorSource
  private osmCollegesSource: VectorSource
  private osmUniversitiesSource: VectorSource
  private osmSupermarketsSource: VectorSource
  private osmFireStationsSource: VectorSource
  private osmPlacesOfWorshipSource: VectorSource
  private osmCommunityCentresSource: VectorSource
  private roadAccidentsSource: VectorSource
  public roadAccidentCentreSource: VectorSource
  private osmRasterSource: Raster
  private osLightRasterSource: Raster
  private osRoadRasterSource: Raster
  private osOutdoorRasterSource: Raster
  private satelliteRasterSource: Raster
  public redlinePolygonSource: VectorSource
  public siteLocationPointSource: VectorSource
  private siteLocationPointLayer: VectorLayer<VectorSource>
  public polygonSelect: Select
  private sfLegend: SchemeflowLegend
  private googleControl: GoogleLogoControl
  private attribution: Attribution
  private mapKey: string | undefined

  constructor(options: { redlinePolygonSource: VectorSource; scaleBarUnits: Units | undefined, mapKey: string | undefined }) {
    useGeographic()
    this.redlinePolygonSource = options.redlinePolygonSource
    this.isochroneSource = new VectorSource({ wrapX: false })
    this.polygonSelect = new Select({})
    this.mapKey = options.mapKey;

    // const geojsonFormat = new GeoJSON({
    // featureProjection: 'EPSG:3857',
    // dataProjection: 'EPSG:3857',
    // });

    // TODO fix projections
    const geojsonFormat = new GeoJSON()

    this.siteLocationPointSource = new VectorSource({
      format: geojsonFormat
    })

    this.busStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/bus_stops?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.busStopsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading bus stops. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.railStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/rail_stations?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.railStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading rail stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBusStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["highway"="bus_stop"]'],
          vectorSource: this.osmBusStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          // Rail stations which are neither subway stations nor light rail stations
          osmQuery: ['["railway"="station"]["station"!="subway"]["station"!="light_rail"]'],
          vectorSource: this.osmRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmMetroStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="subway"]'],
          vectorSource: this.osmMetroStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmLightRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="light_rail"]'],
          vectorSource: this.osmLightRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmTramStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="tram_stop"]'],
          vectorSource: this.osmTramStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.supermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/supermarkets?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.supermarketsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading supermarkets. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.pharmaciesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="pharmacy"]'],
          vectorSource: this.pharmaciesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.postOfficesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/post_offices?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.postOfficesSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading post offices. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.primarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/primary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.primarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading primary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.secondarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/secondary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.secondarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading secondary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.chargePointsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/charge_points?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.chargePointsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading charge points. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.ferryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/ferry_ports?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.ferryPortsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading ferry ports. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.metroStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/metro_stops?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.metroStopsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading metro stops. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmChargePointsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="charging_station"]'],
          vectorSource: this.osmChargePointsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFerryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="ferry_terminal"]'],
          vectorSource: this.osmFerryPortsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRestaurantsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="bar"]', '["amenity"="cafe"]'],
          vectorSource: this.osmRestaurantsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBarsAndCafesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="restaurant"]', '["amenity"="fast_food"]'],
          vectorSource: this.osmBarsAndCafesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHospitalsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="hospital"]'],
          vectorSource: this.osmHospitalsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHealthClinicsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="clinic"]', '["amenity"="doctors"]'],
          vectorSource: this.osmHealthClinicsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmKindergartenSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="kindergarten"]'],
          vectorSource: this.osmKindergartenSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="school"]'],
          vectorSource: this.osmSchoolsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCollegesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="college"]'],
          vectorSource: this.osmCollegesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmUniversitiesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="university"]'],
          vectorSource: this.osmUniversitiesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSupermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["shop"="supermarket"]', '["shop"="convenience"]'],
          vectorSource: this.osmSupermarketsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFireStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="fire_station"]'],
          vectorSource: this.osmFireStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmPlacesOfWorshipSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="place_of_worship"]'],
          vectorSource: this.osmPlacesOfWorshipSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCommunityCentresSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="community_centre"]'],
          vectorSource: this.osmCommunityCentresSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.roadAccidentsSource = new VectorSource({
      wrapX: false
    })

    // Road accident centre source is for user drawing interaction to set a
    // new point for road accident location analysis
    this.roadAccidentCentreSource = new VectorSource({
      wrapX: false
    })

    // The centre point can only be a single point, so when a point is added
    // to the source, delete all other points
    this.roadAccidentCentreSource.on('addfeature', (newFeature) => {
      this.roadAccidentCentreSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.roadAccidentCentreSource.removeFeature(feat)
        }
      })
    })

    this.isochroneOriginSource = new VectorSource({
      wrapX: false
    })

    // when a new point is added to the isochrone source, all previous
    // points should be removed so only the latest one is displayed
    this.isochroneOriginSource.on('addfeature', (newFeature) => {
      this.isochroneOriginSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.isochroneOriginSource.removeFeature(feat)
        }
      })
    })

    this.osmRasterSource = new Raster({
      sources: [new OSM()],
      operation: colorFilterOperation
    })

    this.osLightRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_LIGHT_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osOutdoorRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_OUTDOOR_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osRoadRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_MAP_ROAD_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.satelliteRasterSource = new Raster({
      sources: [
        new XYZ({
          url: SATELLITE_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.googleSource = new Google({
      key: GOOGLE_MAPS_API_KEY,
      scale: 'scaleFactor2x',
      highDpi: true,
      styles: [
        {
          featureType: "all",
          elementType: "geometry",
          stylers: [
            { gamma: 0.5 },
          ]
        }
      ],
    })

    // Define layer group for base layer options
    this.baseMapLayers = new LayerGroup({
      layers: [
        // OSM
        new ImageLayer({
          visible: true, // Default = OSM
          properties: {
            name: 'OSM'
          },
          source: this.osmRasterSource,
          minZoom: 3,
          maxZoom: 20
        }),
        // OS Light
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSLight'
          },
          source: this.osLightRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Outdoor
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSOutdoor'
          },
          source: this.osOutdoorRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Road
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSRoad'
          },
          source: this.osRoadRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // Satellite raster
        new ImageLayer({
          visible: false,
          properties: {
            name: 'Satellite'
          },
          source: this.satelliteRasterSource,
          minZoom: 3,
          maxZoom: 18
        }),
        // Google maps
        new WebGLTileLayer({
          visible: false,
          properties: {
            name: 'Google'
          },
          source: this.googleSource,
          minZoom: 3,
          maxZoom: 22
        })
      ]
    })

    this.siteLocationPointLayer = new VectorLayer({
      visible: false,
      source: this.siteLocationPointSource,
      style: iconFromConfig({ item: "site_location", mapKey: this.mapKey }),
      properties: {
        name: 'site_marker'
      }
    })

    this.busStopsLayer = new VectorLayer({
      minZoom: 12,
      visible: false,
      source: this.busStopsSource,
      style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
      properties: {
        name: 'bus_stops'
      }
    })

    this.overlayLayers = new LayerGroup({
      layers: [
        // Isochrones
        new VectorLayer({
          visible: false,
          source: this.isochroneSource,
          style: function (feature) {
            const featId = feature.getId()

            if (featId < 4) {
              // polygon
              return new Style({
                fill: new Fill({
                  color: [...convert.hex.rgb(isochroneColors[feature.getId()!].substring(0, 7)), parseInt(isochroneColors[feature.getId()!].substring(7, 9), 16) / 255]
                }),
                stroke: null
              })
            } else {
              // outer line
              return new Style({
                fill: null,
                stroke: new Stroke({
                  color: 'rgba(180,180,180)'
                })
              })
            }
          },
          properties: {
            name: 'isochrones'
          }
        }),

        // Red line
        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.redlinePolygonSource,
          style: redlineStyle,
          properties: {
            name: 'redline'
          }
        }),

        this.busStopsLayer,

        new VectorLayer({
          minZoom: 9,
          visible: false,
          source: this.railStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.metroStopsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'metro_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBusStopsSource,
          style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bus_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRailStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmMetroStationsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_metro_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmLightRailStationsSource,
          style: iconFromConfig({ item: "light_rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_light_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmTramStopsSource,
          style: iconFromConfig({ item: "tram_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_tram_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.supermarketsSource,
          style: iconFromConfig({ item: "supermarket", mapKey: this.mapKey }),
          properties: {
            name: 'supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.pharmaciesSource,
          style: iconFromConfig({ item: "pharmacy", mapKey: this.mapKey }),
          properties: {
            name: 'pharmacies'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.postOfficesSource,
          style: iconFromConfig({ item: "post_office", mapKey: this.mapKey }),
          properties: {
            name: 'post_offices'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.primarySchoolsSource,
          style: iconFromConfig({ item: "primary_school", mapKey: this.mapKey }),
          properties: {
            name: 'primary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.secondarySchoolsSource,
          style: iconFromConfig({ item: "secondary_school", mapKey: this.mapKey }),
          properties: {
            name: 'secondary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.chargePointsSource,
          style: iconFromConfig({ item: "charge_point", mapKey: this.mapKey }),
          properties: {
            name: 'charge_points'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.ferryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmChargePointsSource,
          style: iconFromConfig({ item: "charge_point", mapKey: this.mapKey }),
          properties: {
            name: 'osm_charge_points'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFerryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'osm_ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRestaurantsSource,
          style: iconFromConfig({ item: "restaurant", mapKey: this.mapKey }),
          properties: {
            name: 'osm_restaurants'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBarsAndCafesSource,
          style: iconFromConfig({ item: "cafe", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bars_cafes'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHospitalsSource,
          style: iconFromConfig({ item: "hospital", mapKey: this.mapKey }),
          properties: {
            name: 'osm_hospitals'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHealthClinicsSource,
          style: iconFromConfig({ item: "clinic", mapKey: this.mapKey }),
          properties: {
            name: 'osm_clinics'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmKindergartenSource,
          style: iconFromConfig({ item: "kindergarten", mapKey: this.mapKey }),
          properties: {
            name: 'osm_kindergarten'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSchoolsSource,
          style: iconFromConfig({ item: "school", mapKey: this.mapKey }),
          properties: {
            name: 'osm_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCollegesSource,
          style: iconFromConfig({ item: "college", mapKey: this.mapKey }),
          properties: {
            name: 'osm_colleges'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmUniversitiesSource,
          style: iconFromConfig({ item: "university", mapKey: this.mapKey }),
          properties: {
            name: 'osm_universities'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSupermarketsSource,
          style: iconFromConfig({ item: "shop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFireStationsSource,
          style: iconFromConfig({ item: "fire_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_fire_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmPlacesOfWorshipSource,
          style: iconFromConfig({ item: "place_of_worship", mapKey: this.mapKey }),
          properties: {
            name: 'osm_places_of_worship'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCommunityCentresSource,
          style: iconFromConfig({ item: "community_centre", mapKey: this.mapKey }),
          properties: {
            name: 'osm_community_centres'
          }
        }),

        // road accident location markers
        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.roadAccidentsSource,
          style: function (feature) {
            if (feature.get('visible')) {
              return accidentMarker[feature.get('severity')]
            } else {
              return null
            }
          },
          properties: {
            name: 'road_accidents'
          }
        }),

        // road accident centre point location marker
        new VectorLayer({
          visible: false,
          source: this.roadAccidentCentreSource,
          // Style: red cross-hairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'collision_centre'
          }
        }),

        // isochrone origin marker
        new VectorLayer({
          visible: false,
          source: this.isochroneOriginSource,
          // Style: red crosshairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'isochrone_origin'
          }
        }),

        // Site location (point) - on top!
        this.siteLocationPointLayer
      ]
    })

    this.sfLegend = new SchemeflowLegend()

    let layers: Array<BaseLayer> | Collection<BaseLayer> | LayerGroup | undefined = [this.baseMapLayers, this.overlayLayers]

    // Set up north arrow
    const colors = ['black', 'darkgrey', 'lightgrey', 'white']
    let compassImg = document.createElement('div')
    for (let style = 1; style < 9; style++) {
      colors.forEach((color) => {
        const arrowImg = document.createElement('img')
        arrowImg.setAttribute('src', '/images/northarrows/northarrow-style' + style + '-' + color + '.svg')
        arrowImg.setAttribute('class', 'north_arrow_style' + style + '_' + color)
        compassImg.appendChild(arrowImg)
      })
    }

    const rotateControl = new Rotate({
      autoHide: false,
      resetNorth: () => {}, // Do nothing when clicked
      label: compassImg,
      tipLabel: 'Compass'
    })

    const scaleLine = new ScaleLine({
      bar: true,
      minWidth: 100,
      units: options.scaleBarUnits,
    })

    this.googleControl = new GoogleLogoControl();
    this.attribution = new Attribution({
      collapsible: false,
    });

    // Create map
    this.map = new Map({
      layers: layers,
      controls: [rotateControl, scaleLine, this.sfLegend],
      interactions: [this.polygonSelect],
      pixelRatio: 2,
      view: new View({
        zoom: this.zoom,
        center: [this.longitude, this.latitude]
      })
    })
  }

  createErrorEvent(detail: string): void {
    let errorBus = useEventBus('error')
    errorBus.emit(detail)
  }

  setBaseLayer(layerName: string): void {
    this.baseMapLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === layerName) {
        layer.setVisible(true)
        // Set min and max zoom of the map to equal the base layer
        let currentZoom = this.map.getView().getZoom()
        let currentCenter = this.map.getView().getCenter()
        let minZoom = layer.getMinZoom()
        let maxZoom = layer.getMaxZoom()
        this.map.setView(
          new View({
            zoom: currentZoom,
            center: currentCenter,
            // Had to ±0.25 to account for rounding errors
            minZoom: minZoom + 0.25,
            maxZoom: maxZoom - 0.25
          })
        )
      } else {
        layer.setVisible(false)
      }
    })

    // Show Google logo if Google map
    if (layerName == "Google") {
      this.googleControl.setMap(this.map)
      this.attribution.setMap(this.map)
    } else {
      this.googleControl.setMap(null)
      this.attribution.setMap(null)
    }
  }

  toggleOverlayLayer(overlayLayerName: string, visible: boolean): void {
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === overlayLayerName) {
        layer.setVisible(visible)
      }
    })
  }

  lockMap(): void {
    this.map.getInteractions().clear()
    this.map.addInteraction(this.polygonSelect)
  }

  unlockMap(): void {
    this.map.getInteractions().clear()
    this.map.addInteraction(this.polygonSelect)
    defaults({
      altShiftDragRotate: false,
      doubleClickZoom: true,
      keyboard: false,
      mouseWheelZoom: true,
      shiftDragZoom: false,
      pinchRotate: false,
      pinchZoom: true,
      dragPan: true
    }).forEach((interaction) => this.map.addInteraction(interaction))
  }

  getMap(): Map {
    return this.map
  }

  setCenter(longitude: number, latitude: number): void {
    this.longitude = longitude
    this.latitude = latitude
    this.map.getView().setCenter([this.longitude, this.latitude])
  }

  setZoom(zoom: number): void {
    this.zoom = zoom
    this.map.getView().setZoom(this.zoom)
  }

  setPinLocation(longitude: number, latitude: number): void {
    this.siteLocationPointSource.clear()
    this.siteLocationPointSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  setTarget(target?: string | HTMLElement | undefined): void {
    this.map.setTarget(target)
  }

  clearIsochrone() {
    this.isochroneSource.clear()
  }

  setIsochrone(isochroneGeoJSON: any) {
    this.clearIsochrone()
    const features = new GeoJSON().readFeatures(isochroneGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })
    this.isochroneSource.addFeatures(features)
  }

  setPolygonSelectable(value: boolean): void {
    this.polygonSelect.setActive(value)
  }

  async exportPNG(filename: string): void {
    this.getPNGImageB64().then((dataURL) => {
      const link = document.createElement('a')
      link.href = dataURL
      link.download = filename
      link.click()
    })
  }

  async getPNGImageB64(): any {
    const exportCanvasScale = 2.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toPng(this.map.getTargetElement(), exportOptions)
    await toPng(this.map.getTargetElement(), exportOptions)

    return toPng(this.map.getTargetElement(), exportOptions)
  }

  async exportBlob(): Promise<Blob | null> {
    const exportCanvasScale = 2.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toBlob(this.map.getTargetElement(), exportOptions)
    await toBlob(this.map.getTargetElement(), exportOptions)

    return toBlob(this.map.getTargetElement(), exportOptions)
  }

  setBusStopsStyle(func: Function): void {
    this.busStopsLayer.setStyle(func)
    this.busStopsLayer.changed()
  }

  setLegendItems(items: Array<olLegendItemOptions>): void {
    this.sfLegend.clearLegend()

    items.forEach((item) => {
      this.sfLegend.addItem(item)
    })
  }

  setIsochroneStyles(styleList: Array<Style>): void {
    // Apply list of styles to isochrones
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === 'isochrones') {
        layer.setStyle(function (feature) {
          const featId = feature.getId()

          // if featId < 4, it's a polygon, if > 4, a line. For
          // polygons, need to apply appropriate fill style with no
          // outline, and for lines, apply stroke style with no
          // fill.
          if (featId < 4) {
            // polygon, isochrone fill
            return new Style({
              fill: styleList[featId % 4].getFill(),
              stroke: null
            })
          } else {
            // line, ischrone outer edge line
            return new Style({
              fill: null,
              stroke: styleList[featId % 4].getStroke()
            })
          }
        })
      }
    })
  }

  setColorFilters(grayscaleFilter: boolean, alpha: number): void {
    // Set each raster source operation to the new function
    ;[this.osmRasterSource, this.osLightRasterSource, this.osOutdoorRasterSource, this.osRoadRasterSource, this.satelliteRasterSource].forEach((source) => {
      source.on('beforeoperations', function (event) {
        event.data.alpha = alpha
        event.data.grayscaleFilter = grayscaleFilter
      })
      source.changed()
    })
  }

  setIsochroneOrigin(longitude: number, latitude: number): void {
    this.isochroneOriginSource.clear()
    this.isochroneOriginSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  getIsochroneOrigin(): [number | undefined, number | undefined] {
    const features = this.isochroneOriginSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

  public enableMovingSiteLocationPoint(): void {
    let siteLocationModify = new Modify({
      pixelTolerance: 20,
      source: this.siteLocationPointSource,
      style: function (feature) {
        return null
      }
    })
    this.map.addInteraction(siteLocationModify)
  }

  public clearInteractions(): void {
    this.map.getInteractions().clear()
  }

  public clearRoadAccidents(): void {
    this.roadAccidentsSource.clear()
  }

  public setRoadAccidents(roadAccidentGeoJSON: any): void {
    this.clearRoadAccidents()

    // Ensure GeoJSON features have the right projection for drawing on map view
    const features = new GeoJSON().readFeatures(roadAccidentGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })

    // Add accident features to map
    this.roadAccidentsSource.addFeatures(features)
  }

  // Set the centre point of collision analysis, from which the radius is measured
  public setCollisionCentrePoint(longitude: number, latitude: number): void {
    this.roadAccidentCentreSource.clear()
    this.roadAccidentCentreSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  // Get the centre point for collision analysis, drawn by map interaction
  // onto the raodAccidentCentreSource
  public getCollisionCentrePoint(): [number | undefined, number | undefined] {
    const features = this.roadAccidentCentreSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

}
