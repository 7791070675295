import richTextXMLPrefix from "@/assets/xml/richTextXMLPrefix.xml?raw";
import richTextXMLSuffix from "@/assets/xml/richTextXMLSuffix.xml?raw";

export const generatePolicyTitleString = (policy) => {
    let title = policy.title;
    title = title + ", " + policy.owner;
    if (policy.month || policy.year) {
        title = title + ",";
    }

    if (policy.month) {
        title = title + " " + policy.month;
    }

    if (policy.year) {
        title = title + " " + policy.year;
    }

    return title;
};

export const generatePolicyHTML = (policyDocuments) => {
    let html = "<h3 class='section_heading_2'>2.3 Regional and Local Policy</h3>";
    if (policyDocuments) {
        for (let i = 0; i < policyDocuments.length; i++) {
            if (policyDocuments[i].included) {
                // Generate HTML title string
                const newTitle = document.createElement("h4");
                newTitle.classList.add("section_heading_3");
                newTitle.innerText = generatePolicyTitleString(policyDocuments[i]);
                newTitle.setAttribute("data-policy-id", policyDocuments[i].id);

                const newParagraph = document.createElement("div");
                newParagraph.classList.add("normal_text");
                newParagraph.classList.add("policy_paragraph");
                newParagraph.contentEditable = "plaintext-only";
                newParagraph.setAttribute("data-policy-id", policyDocuments[i].id);
                newParagraph.innerText = policyDocuments[i].summary;

                // Generate summary HTML
                html = html + newTitle.outerHTML + newParagraph.outerHTML;
                newTitle.remove();
                newParagraph.remove();
            }
        }
        // Add final chunk of summary text
        let summaryhtml =
            "<div class='summary'><h3 class='section_heading_2'>2.4 Summary</h3> <p class=normal_text'>In summary, the planning policy described in this chapter aims to ensure that sustainable development is prioritised within the local area, and future users have opportunities to maximise travel to the site by sustainable modes rather than via single occupancy vehicles.</p></div>";
        html = html + summaryhtml;
    }

    return html;
};

export const generatePolicyXMLString = (policyDocuments) => {
    let xml = "";
    if (policyDocuments) {
        xml = richTextXMLPrefix;
        for (let i = 0; i < policyDocuments.length; i++) {
            if (policyDocuments[i].included) {
                const newTitle = generatePolicyTitleString(policyDocuments[i]);
                const newParagraph = policyDocuments[i].summary;

                xml =
                    xml +
                    `
                    <w:p>
                        <w:r>
                            <w:rPr>
                                <w:b/>
                            </w:rPr>
                            <w:t>${newTitle}</w:t>
                        </w:r>
                    </w:p>
                    <w:p>
                        <w:r>
                            <w:t>${newParagraph}</w:t>
                        </w:r>
                    </w:p>
                `;
            }
        }
        xml = xml + richTextXMLSuffix;
    }

    return xml;
};
