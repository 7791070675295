import { Style, Fill, Icon, Circle } from "ol/style";
import { useUserStore } from "@/stores/UserStore";
import { SFLegendItem, SFLegendIconFromConfig } from "@/maps/sflegend";
import { amenitiesDefaultStyles, legendDefaultLabels } from "@/maps/constants";
import { useFormDataStore } from "@/stores/FormDataStore";

function getIconConfig(options) {
    // Look up icon config from map styles config
    // If exists, prepend path with API route for downloading image and return
    const formDataStore = useFormDataStore();
    if (formDataStore.formData[options.mapKey]?.styles?.icons?.[options.item]) {
        return {
            img: `/api/storage/icons/download/${formDataStore.formData[options.mapKey].styles.icons[options.item].path}`,
            width: formDataStore.formData[options.mapKey].styles.icons[options.item].width,
            height: formDataStore.formData[options.mapKey].styles.icons[options.item].height,
            anchor: formDataStore.formData[options.mapKey].styles.icons[options.item].anchor,
            bg: false,
        };
    }

    // If no map-specific config, use regional override
    // If no regional override, use global default
    const userStore = useUserStore();
    let config = userStore.thisRegionConfig?.icons?.[options.item] || amenitiesDefaultStyles[options.item];

    // Add path prefix for local/codebase icons
    return {
        img: `/images/icons/${config.img}`,
        width: config.width,
        height: config.height,
        bg: config.bg,
        anchor: config.anchor,
    };
}

export function iconFromConfig(options) {
    let config = getIconConfig(options);
    return [
        ...(config.bg
            ? [
                  new Style({
                      image: new Circle({
                          radius: (config.height + 4) / 2,
                          fill: new Fill({
                              color: config.bg.color,
                          }),
                      }),
                  }),
              ]
            : []),
        new Style({
            image: new Icon({
                src: config.img,
                width: config.width,
                height: config.height,
                anchor: config.anchor,
            }),
        }),
    ];
}

export function legendItemFromConfig(options) {
    // Defaults
    const userStore = useUserStore();
    const label = userStore.thisRegionConfig.legend?.[options.item] || legendDefaultLabels[options.item];
    let iconConfig = getIconConfig(options);

    return new SFLegendItem({
        title: label,
        icon: new SFLegendIconFromConfig(iconConfig),
    });
}
