/* global Word */
import { SF_XML_NAMESPACE } from "./contentControlConstants";

export async function updateXmlCustomPartWithContext(context, key, xmlEscapedData) {
    const xmlCustomParts = context.document.customXmlParts;
    const scopedXmlParts = xmlCustomParts.getByNamespace(SF_XML_NAMESPACE);
    const sfXmlPart = scopedXmlParts.getOnlyItem();

    const nsmapping = { sf: SF_XML_NAMESPACE };

    var xmlString = `<${key} xmlns="${SF_XML_NAMESPACE}">${xmlEscapedData}</${key}>`;
    const xpath = `/sf:schemeflow/sf:${key}`;

    // query to check if element already exists. If it does, update it; if not,
    // create it
    // Query returns an OfficeExtension.ClientResult<string[]> object. To access
    // the value, need to run context.sync()
    const queryResult = sfXmlPart.query(xpath, nsmapping);
    await context.sync();

    if (queryResult.value.length) {
        // tag already exists, update it
        sfXmlPart.updateElement(xpath, xmlString, nsmapping);
    } else {
        // tag does not yet exist, create it as a child of Schemeflow XML custom part
        sfXmlPart.insertElement("/sf:schemeflow", xmlString, nsmapping);
    }
}

// N.B. updateXmlCustomPart is used by TextTweaker.vue, which is a globally
// registered component, and so causes issues if it loads a module which uses a
// pinia store, as the store may not be created before the module is loaded.
export async function updateXmlCustomPart(key, data) {
    await Word.run(async (context) => {
        await updateXmlCustomPartWithContext(context, key, data);
        await context.sync();
    });
}

export async function getXmlCustomPartValueWithContext(context, key) {
    const xmlCustomParts = context.document.customXmlParts;
    const scopedXmlParts = xmlCustomParts.getByNamespace(SF_XML_NAMESPACE);
    const sfXmlPart = scopedXmlParts.getOnlyItemOrNullObject();

    sfXmlPart.load();
    await context.sync();

    if (sfXmlPart.isNullObject) {
        return "";
    }

    const nsmapping = { sf: SF_XML_NAMESPACE };

    // Query returns an OfficeExtension.ClientResult<string[]> object. To access
    // the value, need to run context.sync()
    const result = sfXmlPart.query(`/sf:schemeflow/sf:${key}`, nsmapping);
    await context.sync();

    // There will only be one value returned by the query, unless the XML has
    // become malformed, so use first (and only) value. This is the whole XML
    // element, including tags
    let xmlValue = result.value[0];

    // Remove XML tags from result, to leave only the value itself
    const prefix = `<${key} xmlns="${SF_XML_NAMESPACE}">`;
    const suffix = `</${key}>`;

    if (xmlValue?.startsWith(prefix)) {
        xmlValue = xmlValue.slice(prefix.length);
    }
    if (xmlValue?.endsWith(suffix)) {
        xmlValue = xmlValue.slice(0, -suffix.length);
    }

    return xmlValue;
}

export async function getXmlCustomPartValue(key) {
    let value;
    await Word.run(async (context) => {
        value = await getXmlCustomPartValueWithContext(context, key);
    });
    return value;
}
