<template>
    <SchemeflowMap
        :key="props.mapKey"
        :mapKey="props.mapKey"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'amenities']"
        :amenities="props.amenities"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { useFormDataStore } from "../../stores/FormDataStore";
import { onMounted, onUnmounted } from "vue";

const store = useFormDataStore();
const props = defineProps({
    mapKey: String,
    amenities: {
        type: Object,
        default() {
            return {};
        },
    },
});

onMounted(() => {
    setTimeout(() => {
        store.maps[props.mapKey].getMap().once("rendercomplete", () => {
            store.mapsReadyForExport[props.mapKey] = true;
        });
        store.maps[props.mapKey].getMap().renderSync();
    }, 5000);
});

onUnmounted(() => {
    store.mapsReadyForExport[props.mapKey] = false;
});
</script>
