<template>
    <link rel="stylesheet" type="text/css" media="all" :href="olCSS" />
    <link rel="stylesheet" type="text/css" media="all" :href="olExtCSS" />
    <link rel="stylesheet" type="text/css" media="all" :href="documentCSS" />
    <link
        v-if="store.formData.stylesheet"
        rel="stylesheet"
        type="text/css"
        media="all"
        :href="store.customerStylesheet"
    />
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import documentCSS from "@/assets/stylesheets/document.css?url";
import olCSS from "ol/ol.css?url";
import olExtCSS from "ol-ext/dist/ol-ext.css?url";

const store = useFormDataStore();
</script>
