<template>
    <SchemeflowMap
        mapKey="cycling_map"
        isochrone-profile="cycle"
        :legendConfigMasterList="['site_loc', 'isochrones']"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { onMounted, onUnmounted } from "vue";
import { useFormDataStore } from "../../stores/FormDataStore";

const store = useFormDataStore();

onMounted(async () => {
    setTimeout(() => {
        store.maps.cycling_map.getMap().once("rendercomplete", () => {
            store.mapsReadyForExport.cycling_map = true;
        });
        store.maps.cycling_map.getMap().renderSync();
    }, 3500);
});

onUnmounted(() => {
    store.mapsReadyForExport.cycling_map = false;
});
</script>
