import siteMapPlaceholder from "@/assets/img/cc_placeholders/SiteLocationMap.png.base64.txt?raw";
import satelliteMapPlaceholder from "@/assets/img/cc_placeholders/SatelliteMap.png.base64.txt?raw";
import highwaysMapPlaceholder from "@/assets/img/cc_placeholders/LocalHighwaysMap.png.base64.txt?raw";
import busStopsMapPlaceholder from "@/assets/img/cc_placeholders/BusStopsMap.png.base64.txt?raw";
import railStationsMapPlaceholder from "@/assets/img/cc_placeholders/RailStationsMap.png.base64.txt?raw";
import amenitiesMapPlaceholder from "@/assets/img/cc_placeholders/AmenitiesMap.png.base64.txt?raw";
import pedestrianMapPlaceholder from "@/assets/img/cc_placeholders/PedestrianIsochrone.png.base64.txt?raw";
import cyclingMapPlaceholder from "@/assets/img/cc_placeholders/CyclingIsochrone.png.base64.txt?raw";
import drivingMapPlaceholder from "@/assets/img/cc_placeholders/DrivingIsochrone.png.base64.txt?raw";
import accidentMapPlaceholder from "@/assets/img/cc_placeholders/AccidentMap.png.base64.txt?raw";

import axios from "axios";
import axiosRetry from "axios-retry";

const axiosInstance = axios.create();
axiosRetry(axiosInstance, { retryDelay: axiosRetry.exponentialDelay });

export const fetchAllContentControlConfig = async () => {
    try {
        const response = await axiosInstance.get("/api/config/contentControlConfig");
        return response.data;
    } catch (error) {
        console.error("Error fetching content control config: ", error);
    }
};

export const fetchContentControlConfigByKey = async (key) => {
    try {
        const response = await axiosInstance.get(`/api/config/contentControlConfig/${key}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching content control config: ", error);
    }
};

export const contentControlPrefixes = {
    TOOLBOX: "sftb_",
    DOCUMENT_PROPERTY: "sfpp_",
    MAP: "sfmp_",
    SUMMARY: "sfsm_",
};

// takes a tag like "sfmp_site_map" and returns an array, [prefix, tagName],
// such as ["sfmp_", "site_map"]. The tagName can be used as the key to
// contentControlConfig to get other cc properties.
export function getContentControlPrefixName(tag) {
    return [tag.slice(0, 5), tag.slice(5)];
}

export const placeholderMaps = {
    site_map: siteMapPlaceholder,
    satellite_map: satelliteMapPlaceholder,
    highways_map: highwaysMapPlaceholder,
    bus_stops_map: busStopsMapPlaceholder,
    rail_stations_map: railStationsMapPlaceholder,
    amenities_map: amenitiesMapPlaceholder,
    pedestrian_map: pedestrianMapPlaceholder,
    cycling_map: cyclingMapPlaceholder,
    driving_map: drivingMapPlaceholder,
    accident_map: accidentMapPlaceholder,
};

export const SF_XML_NAMESPACE = "http://report.schemeflow.com/v1";
