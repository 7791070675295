<template>
    <div ref="map_root" class="map" :style="cssVars"></div>
    <CSSLoader />
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import { useUserStore } from "@/stores/UserStore";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import SchemeflowMap from "../maps/map";
import {
    aspectRatios,
    isochroneDefaults,
    isochroneDefaultStyles,
    isochroneLineStyles,
    siteBoundaryLegendItem,
    highwayMapLegendItems,
    maxInitialZoomLevel,
} from "../maps/constants";
import { watchDebounced } from "@vueuse/core";
import { Style, Fill, Stroke } from "ol/style";
import convert from "color-convert";
import { SFLegendItem, SFLegendIconRectangle } from "@/maps/sflegend";
import CSSLoader from "@/components/CSSLoader.vue";
import { legendItemFromConfig } from "@/maps/configIcons";

const store = useFormDataStore();
const userStore = useUserStore();

const props = defineProps({
    mapKey: String,
    isochroneProfile: {
        type: String,
        default: "select",
    },
    legendConfigMasterList: {
        type: Array,
        default: () => [],
    },
    amenities: {
        type: Object,
        default() {
            return {};
        },
    },
});

const map_root = ref(null);

const cssVars = computed(() => {
    // Scale bar
    let scaleBarVars = {
        "--sf-map-scale-bar-display": store.formData[props.mapKey].styles.scale_bar === "none" ? "none" : "block",
        "--sf-map-scale-bar-bottom":
            store.formData[props.mapKey].styles.scale_bar === "bottom-left" ||
            store.formData[props.mapKey].styles.scale_bar === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-scale-bar-right":
            store.formData[props.mapKey].styles.scale_bar === "top-right" ||
            store.formData[props.mapKey].styles.scale_bar === "bottom-right"
                ? "20px"
                : "auto",
        "--sf-map-scale-bar-left":
            store.formData[props.mapKey].styles.scale_bar === "top-left" ||
            store.formData[props.mapKey].styles.scale_bar === "bottom-left"
                ? "8px"
                : "auto",
        "--sf-map-scale-bar-top":
            store.formData[props.mapKey].styles.scale_bar === "top-left" ||
            store.formData[props.mapKey].styles.scale_bar === "top-right"
                ? "8px"
                : "auto",
    };
    // Legend
    let legendVars = {
        "--sf-map-legend-display": store.formData[props.mapKey].styles.legend === "none" ? "none" : "block",
        "--sf-map-legend-bottom":
            store.formData[props.mapKey].styles.legend === "bottom-left" ||
            store.formData[props.mapKey].styles.legend === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-legend-right":
            store.formData[props.mapKey].styles.legend === "top-right" ||
            store.formData[props.mapKey].styles.legend === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-legend-left":
            store.formData[props.mapKey].styles.legend === "top-left" ||
            store.formData[props.mapKey].styles.legend === "bottom-left"
                ? "8px"
                : "auto",
        "--sf-map-legend-top":
            store.formData[props.mapKey].styles.legend === "top-left" ||
            store.formData[props.mapKey].styles.legend === "top-right"
                ? "8px"
                : "auto",
    };
    // Compass
    let compassVars = {
        "--sf-map-rotate-reset-display": store.formData[props.mapKey].styles.compass === "none" ? "none" : "block",
        "--sf-map-rotate-reset-bottom":
            store.formData[props.mapKey].styles.compass === "bottom-left" ||
            store.formData[props.mapKey].styles.compass === "bottom-right"
                ? "3px"
                : "auto",
        "--sf-map-rotate-reset-right":
            store.formData[props.mapKey].styles.compass === "top-right" ||
            store.formData[props.mapKey].styles.compass === "bottom-right"
                ? "3px"
                : "auto",
        "--sf-map-rotate-reset-left":
            store.formData[props.mapKey].styles.compass === "top-left" ||
            store.formData[props.mapKey].styles.compass === "bottom-left"
                ? "3px"
                : "auto",
        "--sf-map-rotate-reset-top":
            store.formData[props.mapKey].styles.compass === "top-left" ||
            store.formData[props.mapKey].styles.compass === "top-right"
                ? "3px"
                : "auto",
    };
    // North arrow (Compas) style
    let northArrowVars = {
        "--north-arrow-style1-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 1 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style1-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 1 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style1-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 1 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style1-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 1 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style2-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 2 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style2-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 2 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style2-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 2 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style2-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 2 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style3-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 3 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style3-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 3 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style3-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 3 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style3-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 3 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style4-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 4 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style4-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 4 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style4-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 4 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style4-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 4 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style5-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 5 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style5-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 5 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style5-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 5 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style5-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 5 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style6-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 6 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style6-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 6 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style6-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 6 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style6-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 6 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style7-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 7 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style7-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 7 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style7-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 7 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style7-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 7 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
        "--north-arrow-style8-black-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 8 &&
            store.formData[props.mapKey].styles.north_arrow_color === "black"
                ? "block"
                : "none",
        "--north-arrow-style8-darkgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 8 &&
            store.formData[props.mapKey].styles.north_arrow_color === "darkgrey"
                ? "block"
                : "none",
        "--north-arrow-style8-lightgrey-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 8 &&
            store.formData[props.mapKey].styles.north_arrow_color === "lightgrey"
                ? "block"
                : "none",
        "--north-arrow-style8-white-display":
            store.formData[props.mapKey].styles.compass != "none" &&
            store.formData[props.mapKey].styles.north_arrow_style === 8 &&
            store.formData[props.mapKey].styles.north_arrow_color === "white"
                ? "block"
                : "none",
    };

    // Hide north arrow image if
    return {
        "--sf-map-aspect-ratio": store.formData[props.mapKey].styles.aspect_ratio || aspectRatios["16:9"],
        ...scaleBarVars,
        ...legendVars,
        ...compassVars,
        ...northArrowVars,
    };
});

// watchers set up in async function must be explicitly unwatched to avoid
// memory leaks
let unwatchLegendConfig;
let unwatchSiteMarker;
let unwatchIsochroneConfig;
let unwatchIsochroneGeoJSON;
let unwatchIsochroneStyle;
let unwatchBaseLayers;
let unwatchColorFilters;

onMounted(async () => {
    store.maps[props.mapKey] = new SchemeflowMap({
        redlinePolygonSource: store.redlinePolygonSource,
        scaleBarUnits: userStore.thisRegionConfig.scaleBarUnits,
        mapKey: props.mapKey,
    });

    // Load red line polygon from store
    store.updateRedlinePolygon();

    // Update position and zoom of map when it moves
    store.maps[props.mapKey].getMap().on("moveend", storeMapView);

    // Move map to position
    if (store.formData[props.mapKey].center_latitude && store.formData[props.mapKey].center_longitude) {
        store.maps[props.mapKey].setCenter(
            store.formData[props.mapKey].center_longitude,
            store.formData[props.mapKey].center_latitude
        );
    } else {
        store.maps[props.mapKey].setCenter(store.longitude, store.latitude);
    }

    // Set zoom
    if (store.formData[props.mapKey].zoom) {
        store.maps[props.mapKey].setZoom(store.formData[props.mapKey].zoom);
    } else {
        store.maps[props.mapKey].setZoom(store.formData.sitemap_zoom);
    }

    // If no (map-specific) site location value in database, use scheme location
    if (!store.formData[props.mapKey].site_marker_location) {
        store.formData[props.mapKey].site_marker_location = {
            longitude: store.formData.analysis_longitude,
            latitude: store.formData.analysis_latitude,
        };
    }
    // Plot site location marker
    store.maps[props.mapKey].setPinLocation(
        store.formData[props.mapKey].site_marker_location.longitude,
        store.formData[props.mapKey].site_marker_location.latitude
    );

    // Update site marker position in formData when it is moved
    store.maps[props.mapKey].siteLocationPointSource.on("change", function () {
        const newFeatures = store.maps[props.mapKey].siteLocationPointSource.getFeatures();
        if (newFeatures.length) {
            const newPosition = newFeatures[0].getGeometry().flatCoordinates;
            store.formData[props.mapKey].site_marker_location.longitude = newPosition[0];
            store.formData[props.mapKey].site_marker_location.latitude = newPosition[1];
        }
    });

    // Show/hide site location pin / site boundary based on value in formData
    store.maps[props.mapKey].toggleOverlayLayer("site_marker", store.formData[props.mapKey].styles.site_marker);
    store.maps[props.mapKey].toggleOverlayLayer("redline", store.formData[props.mapKey].styles.site_boundary);

    // Show/hide isochrones based on prop value
    store.maps[props.mapKey].toggleOverlayLayer("isochrones", true);

    store.maps[props.mapKey].setTarget(map_root.value);

    // legend configuration
    // masterList is a list of other keys of legend_config, selecting which are
    // displayed in the legend and in which order. The lists associated with
    // other keys can then be updated by relevant components without reference
    // to other elements within the legend
    store.maps[props.mapKey].legend_config = {
        masterList: props.legendConfigMasterList,
        site_loc: [siteBoundaryLegendItem],
        highway: highwayMapLegendItems,
        bus_stops: [],
        rail_stations: [legendItemFromConfig({ item: "rail_station", mapKey: props.mapKey })],
        metro: [legendItemFromConfig({ item: "metro_station", mapKey: props.mapKey })],
        isochrones: [],
        amenities: [],
        road_accidents: [],
    };

    unwatchLegendConfig = watch(
        () => store.maps[props.mapKey].legend_config,
        () => {
            updateMapLegend();
        },
        {
            deep: true,
            immediate: true,
        }
    );

    unwatchBaseLayers = watch(
        () => store.formData[props.mapKey].styles.base_map,
        (newValue) => {
            store.maps[props.mapKey].setBaseLayer(newValue);
        },
        {
            immediate: true,
        }
    );

    unwatchSiteMarker = watch(
        [
            () => store.formData[props.mapKey].styles.site_marker,
            () => store.formData[props.mapKey].styles.site_boundary,
        ],
        () => {
            let newSiteLocConfig = [];

            // Site marker
            if (store.formData[props.mapKey].styles.site_marker) {
                newSiteLocConfig.push(legendItemFromConfig({ item: "site_location", mapKey: props.mapKey }));
            }

            // Site boundary
            if (store.formData[props.mapKey].styles.site_boundary) {
                newSiteLocConfig.push(siteBoundaryLegendItem);
            }

            store.maps[props.mapKey].legend_config.site_loc = newSiteLocConfig;

            // Toggle layers on map
            store.maps[props.mapKey].toggleOverlayLayer("redline", store.formData[props.mapKey].styles.site_boundary);
            store.maps[props.mapKey].toggleOverlayLayer("site_marker", store.formData[props.mapKey].styles.site_marker);
        },
        {
            immediate: true,
        }
    );

    unwatchColorFilters = watch(
        [() => store.formData[props.mapKey].styles.grayscale_filter, () => store.formData[props.mapKey].styles.opacity],
        () => {
            store.maps[props.mapKey].setColorFilters(
                store.formData[props.mapKey].styles.grayscale_filter,
                store.formData[props.mapKey].styles.opacity
            );
        },
        {
            immediate: true,
        }
    );

    // Set up isochrones if required
    // if no saved values, use appropriate defaults
    if (!store.formData[props.mapKey].isochrone_config) {
        // If there is a firm specific default for map style, use that, else use global default
        if (store.formData[props.mapKey].styles.isochrone_config) {
            store.formData[props.mapKey].isochrone_config = store.formData[props.mapKey].styles.isochrone_config;
        } else {
            store.formData[props.mapKey].isochrone_config = isochroneDefaults[props.isochroneProfile];
        }
    }
    // If no isochrone styles saved, initialise with default values
    if (!store.formData[props.mapKey].isochrone_styles) {
        // if there is a firm specific default for map style, use that, else use global default
        if (store.formData[props.mapKey].styles.isochrone_styles) {
            store.formData[props.mapKey].isochrone_styles = store.formData[props.mapKey].styles.isochrone_styles;
        } else {
            store.formData[props.mapKey].isochrone_styles = isochroneDefaultStyles;
        }
    }

    // Initial isochrone display
    store.maps[props.mapKey].toggleOverlayLayer(
        "isochrones",
        store.formData[props.mapKey].isochrone_config.show_isochrones
    );

    // If isochrone is stored, use that to draw map. If not, fetch
    // isochrone if isochrone should be displayed
    if (store.formData[props.mapKey].isochrone_geojson) {
        store.maps[props.mapKey].setIsochrone(store.formData[props.mapKey].isochrone_geojson);
        if (store.formData[props.mapKey].isochrone_geojson.bbox?.length) {
            store.maps[props.mapKey].getMap().getView().fit(store.formData[props.mapKey].isochrone_geojson.bbox);
        }
    } else if (store.formData[props.mapKey].isochrone_config.show_isochrones) {
        await store.fetchIsochrones(props.mapKey);
        store.maps[props.mapKey].setIsochrone(store.formData[props.mapKey].isochrone_geojson);
        store.maps[props.mapKey].getMap().getView().fit(store.formData[props.mapKey].isochrone_geojson.bbox);
        if (store.formData[props.mapKey].isochrone_geojson.bbox?.length) {
            store.maps[props.mapKey].getMap().getView().fit(store.formData[props.mapKey].isochrone_geojson.bbox);
        }
    }

    // Store updated map view after fitting to isochrone
    storeMapView();

    unwatchIsochroneConfig = watchDebounced(
        () => store.formData[props.mapKey].isochrone_config,
        async () => {
            store.maps[props.mapKey].toggleOverlayLayer(
                "isochrones",
                store.formData[props.mapKey].isochrone_config.show_isochrones
            );

            if (store.formData[props.mapKey].isochrone_config.show_isochrones) {
                await store.fetchIsochrones(props.mapKey);
            }

            updateIsochroneLegend();
        },
        {
            debounce: 2000,
            maxWait: 2500,
            deep: true,
        }
    );

    unwatchIsochroneGeoJSON = watch(
        () => store.formData[props.mapKey].isochrone_geojson,
        (isochrone_data) => {
            // Set isochrone on map
            store.maps[props.mapKey].setIsochrone(isochrone_data);

            // fit view to data if bbox provided by backend
            if (isochrone_data.bbox?.length) {
                store.maps[props.mapKey].getMap().getView().fit(isochrone_data.bbox);
            }
        }
    );

    // Watch for changes to isochrone styles, and update isochrones and legend
    unwatchIsochroneStyle = watch(
        () => store.formData[props.mapKey].isochrone_styles,
        () => {
            updateIsochroneStyle();
            updateIsochroneLegend();
        },
        {
            deep: true,
            immediate: true,
        }
    );

    // if needed, reposition map to include appropriate features.
    // If map has bbox_to_fit set, use it and unset
    // Do this at the end of onMounted function to ensure this is the view
    // actually displayed (view may be changed by isochrone config, for exmaple)
    if (store.formData[props.mapKey].bbox_to_fit?.length) {
        const origZoom = store.maps[props.mapKey].getMap().getView().getZoom();
        store.maps[props.mapKey].getMap().getView().fit(store.formData[props.mapKey].bbox_to_fit);
        //  Zoom out one level to make sure features really are in view (not right on the edge)
        store.maps[props.mapKey].getMap().getView().adjustZoom(-0.25);

        // if Zoom level is closer than default view was, zoom out to default
        // zoom level

        if (store.maps[props.mapKey].getMap().getView().getZoom() > origZoom) {
            store.maps[props.mapKey].getMap().getView().setZoom(origZoom);
        }

        // If the current map type has a maximum zoom level, ensure new zoom
        // level is less than that. Only when using bbox_to_fit -- user is
        // allowed to demand a more zoomed in map without it being reset when
        // the map is next loaded
        let maxInitialZoom;
        if (props.mapKey in maxInitialZoomLevel) {
            maxInitialZoom = maxInitialZoomLevel[props.mapKey];

            if (store.maps[props.mapKey].getMap().getView().getZoom() > maxInitialZoom) {
                store.maps[props.mapKey].getMap().getView().setZoom(maxInitialZoom);
            }
        }

        // Set bbox to null as intial view has been set, to avoid zooming to
        // default view on reload after user adjustment
        store.formData[props.mapKey].bbox_to_fit = null;

        // Store new map view given by bbox_to_fit to map
        storeMapView();
    }

    watch(
        [() => store.formData[props.mapKey].amenities_layers],
        () => {
            setAmenitiesOverlayLayers();
            setAmenitiesLegendItems();
        },
        {
            immediate: true,
            deep: true,
        }
    );
});

function storeMapView() {
    const view = store.maps[props.mapKey].getMap().getView();
    const center = view.getCenter();
    store.formData[props.mapKey].center_longitude = center[0];
    store.formData[props.mapKey].center_latitude = center[1];
    store.formData[props.mapKey].zoom = view.getZoom();
}

function updateMapLegend() {
    const legendItems = [];

    // For each category item in the legend config master list, get all items
    // for legend from that category
    store.maps[props.mapKey].legend_config.masterList.forEach((item) => {
        legendItems.push(...store.maps[props.mapKey].legend_config[item]);
    });

    store.maps[props.mapKey].setLegendItems(legendItems);
}

const profileToTravelMode = {
    pedestrian: "walk",
    bicycle: "cycle",
    auto: "drive",
};

function updateIsochroneLegend() {
    const profile = store.formData[props.mapKey].isochrone_config.profile;
    const range_list = store.formData[props.mapKey].isochrone_config.range_list;
    const range_type = store.formData[props.mapKey].isochrone_config.range_type;

    const isochroneLegendItems = [];

    if (store.formData[props.mapKey].isochrone_config.show_isochrones) {
        for (let idx = 0; idx < range_list.length; idx++) {
            let title = "";

            if (range_type == "time") {
                title += `${range_list[idx]} min `;
            } else if (range_type == "distance" && range_list[idx] < 1000) {
                title += `${range_list[idx]} m `;
            } else {
                let distKm = range_list[idx] / 1000;
                title += `${distKm.toFixed(1)} km `;
            }

            title += profileToTravelMode[profile];

            const style = store.formData[props.mapKey].isochrone_styles[idx];

            isochroneLegendItems.push(
                new SFLegendItem({
                    title: title,
                    icon: new SFLegendIconRectangle({
                        fillColor: style.fill_color.substring(0, 7),
                        fillOpacity: style.show_fill ? parseInt(style.fill_color.substring(7, 9), 16) / 255 : 0.0,
                        outlineColor: style.outline_color.substring(0, 7),
                        outlineStyle: style.show_outline ? style.outline_style : "none",
                        outlineWidth: 2,
                    }),
                })
            );
        }
    }

    store.maps[props.mapKey].legend_config.isochrones = isochroneLegendItems;
}

function updateIsochroneStyle() {
    const isoStyles = [];

    for (let idx = 0; idx < 4; idx++) {
        isoStyles.push(getIsochroneStyle(idx));
    }

    store.maps[props.mapKey].setIsochroneStyles(isoStyles);
}

function getIsochroneStyle(index) {
    const style = store.formData[props.mapKey].isochrone_styles[index];

    // Calcuate fill colour and opacity
    const fillColor = style.fill_color.substring(0, 7);
    const fillOpacity = style.show_fill ? parseInt(style.fill_color.substring(7, 9), 16) / 255 : 0.0;

    const fillColorValue = [...convert.hex.rgb(fillColor), fillOpacity];

    // Outline style and opacity
    const outlineColor = style.outline_color.substring(0, 7);
    let outlineOpacity = parseInt(style.outline_color.substring(7, 9), 16) / 255;
    if (style.outline_style === "none" || style.show_outline === false) {
        outlineOpacity = 0.0;
    }
    const outlineColorValue = [...convert.hex.rgb(outlineColor), outlineOpacity];

    // Set line style, using appropriate values for isochrones and legend /display
    let lineDash = isochroneLineStyles[style.outline_style].lineDash;

    if (style.outlineStyle === "dotted") {
        lineDash = [1, 3 * style.outline_width];
    }

    return new Style({
        fill: new Fill({
            color: fillColorValue,
        }),
        stroke: new Stroke({
            color: outlineColorValue,
            lineCap: isochroneLineStyles[style.outline_style].lineCap,
            lineDash: lineDash,
            width: style.outline_width,
        }),
    });
}

function setAmenitiesLegendItems() {
    store.maps[props.mapKey].legend_config.amenities = Object.keys(props.amenities).flatMap((key) =>
        store.formData[props.mapKey].amenities_layers?.[key]
            ? [legendItemFromConfig({ item: props.amenities[key].legend, mapKey: props.mapKey })]
            : []
    );
}

function setAmenitiesOverlayLayers() {
    // for each layer, toggle based on the value of the form input
    if (store.formData[props.mapKey].amenities_layers) {
        for (const [key, value] of Object.entries(store.formData[props.mapKey].amenities_layers)) {
            store.maps[props.mapKey].toggleOverlayLayer(key, value);
        }
    }
}

onUnmounted(() => {
    unwatchLegendConfig();
    unwatchSiteMarker();
    unwatchBaseLayers();
    unwatchColorFilters();

    if (unwatchIsochroneConfig) {
        unwatchIsochroneConfig();
    }

    if (unwatchIsochroneGeoJSON) {
        unwatchIsochroneGeoJSON();
    }

    if (unwatchIsochroneStyle) {
        unwatchIsochroneStyle();
    }
});
</script>

<style>
:root {
    --sf-map-aspect-ratio: v-bind(store.formData[props.mapKey].styles.aspect_ratio);
}
</style>
