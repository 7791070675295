<template>
    <SchemeflowMap
        mapKey="satellite_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'amenities']"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { onMounted, onUnmounted } from "vue";
import { useFormDataStore } from "../../stores/FormDataStore";

const store = useFormDataStore();

onMounted(() => {
    setTimeout(() => {
        store.maps.satellite_map.getMap().once("rendercomplete", () => (store.mapsReadyForExport.satellite_map = true));
        store.maps.satellite_map.getMap().renderSync();
    }, 3000);
});

onUnmounted(() => {
    store.mapsReadyForExport.satellite_map = false;
});
</script>
