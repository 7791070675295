<template>
    <SchemeflowMap
        mapKey="site_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones']"
    ></SchemeflowMap>
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import { onMounted, onUnmounted } from "vue";
import { onKeyStroke } from "@vueuse/core";
import GeoJSON from "ol/format/GeoJSON";
import { useGeographic } from "ol/proj";
import center from "@turf/center";
import SchemeflowMap from "@/components/SchemeflowMap.vue";

const store = useFormDataStore();

let deletePolygonListener;

const removePolygonDeleteListener = () => {
    if (deletePolygonListener) {
        deletePolygonListener();
    }
};

onMounted(async () => {
    store.maps.site_map.polygonSelect.on("select", (select_event) => {
        // Remove any existing backspace listeners
        removePolygonDeleteListener();

        // For newly selected features, add backspace listener to delete
        if (select_event.selected.length) {
            deletePolygonListener = onKeyStroke("Backspace", (key_event) => {
                key_event.preventDefault();
                // Remove this feature from the polygonSource
                select_event.selected.forEach((feature) =>
                    store.maps.site_map.redlinePolygonSource.removeFeature(feature)
                );
                // Remove listener after one time
                removePolygonDeleteListener();

                // Save geojson
                useGeographic();
                const format = new GeoJSON({
                    featureProjection: "EPSG:3857",
                    dataProjection: "EPSG:3857",
                });
                const features = store.maps.site_map.redlinePolygonSource.getFeatures();
                store.formData.redline_polygon_geojson = format.writeFeatures(features);

                // Calculate centre of polygons and save to DB
                // Only if there is at least one polygon!
                if (features.length) {
                    const polygon_center = center(JSON.parse(store.formData.redline_polygon_geojson));

                    store.formData.analysis_latitude = polygon_center.geometry.coordinates[1];
                    store.formData.analysis_longitude = polygon_center.geometry.coordinates[0];
                }
            });
        }
    });
    setTimeout(() => {
        store.maps.site_map.getMap().once("rendercomplete", () => (store.mapsReadyForExport.site_map = true));
        store.maps.site_map.getMap().renderSync();
    }, 3000);
});

onUnmounted(() => {
    store.mapsReadyForExport.site_map = false;
});
</script>
