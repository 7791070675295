export const generateCollisionSummaryTable = (counts) => {
    const collisionTable = document.createElement("table");
    collisionTable.classList.add("normal_text");
    collisionTable.classList.add("collision_summary_table");

    // Header rows
    const tblHead = document.createElement("thead");
    const hdrRow1 = document.createElement("tr");

    const studyArea = document.createElement("th");
    studyArea.rowSpan = 2;
    studyArea.setAttribute("scope", "col");
    studyArea.innerText = "Study Area";
    hdrRow1.appendChild(studyArea);

    const collisionSeverity = document.createElement("th");
    collisionSeverity.colSpan = 3;
    collisionSeverity.setAttribute("scope", "col");
    collisionSeverity.innerText = "Collision Severity";
    hdrRow1.appendChild(collisionSeverity);

    const total = document.createElement("th");
    total.rowSpan = 2;
    total.setAttribute("scope", "col");
    total.innerText = "Total";
    hdrRow1.appendChild(total);

    tblHead.appendChild(hdrRow1);

    const hdrRow2 = document.createElement("tr");

    const slightHdr = document.createElement("th");
    slightHdr.setAttribute("scope", "col");
    slightHdr.innerText = "Slight";
    hdrRow2.appendChild(slightHdr);

    const seriousHdr = document.createElement("th");
    seriousHdr.setAttribute("scope", "col");
    seriousHdr.innerText = "Serious";
    hdrRow2.appendChild(seriousHdr);

    const fatalHdr = document.createElement("th");
    fatalHdr.setAttribute("scope", "col");
    fatalHdr.innerText = "Fatal";
    hdrRow2.appendChild(fatalHdr);

    tblHead.appendChild(hdrRow2);

    collisionTable.appendChild(tblHead);

    // Table content
    const tblBody = document.createElement("tbody");
    const tr = document.createElement("tr");

    const count = document.createElement("td");
    count.innerText = "Count";
    tr.appendChild(count);

    const slight = document.createElement("td");
    slight.innerText = counts.slight;
    tr.appendChild(slight);

    const serious = document.createElement("td");
    serious.innerText = counts.serious;
    tr.appendChild(serious);

    const fatal = document.createElement("td");
    fatal.innerText = counts.fatal;
    tr.appendChild(fatal);

    const totalCount = document.createElement("td");
    totalCount.innerText = counts.total;
    tr.appendChild(totalCount);

    tblBody.appendChild(tr);

    collisionTable.appendChild(tblBody);

    return collisionTable.outerHTML;
};
