<template>
    <div class="mt-8" v-if="store.formData[props.summaryKey] || defaultShowTweakers">
        <div v-if="isGenerating" class="flex justify-center text-center text-md font-medium text-gray-900">
            <ArrowPathIcon class="animate-spin h-6 w-6 mr-2 fill-sf-orange"></ArrowPathIcon>
            Generating
        </div>
        <div v-else class="flex justify-center text-center text-md font-medium text-gray-900">
            <SparklesIcon class="h-6 w-6 mr-2 fill-sf-orange"></SparklesIcon>
            Give the AI some feedback
        </div>
    </div>

    <div class="flex text-center justify-center mb-8 mt-2 w-full">
        <div v-if="store.formData[props.summaryKey] || defaultShowTweakers">
            <div class="flex-wrap">
                <button
                    v-for="(option, index) in Object.keys(tweakerOptions)"
                    :key="index"
                    @click="handleTweakSummary(option)"
                    :disabled="isGenerating"
                    class="p-2 m-1 text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sf-orange border border-sf-orange inline-flex items-center bg-white text-sf-orange hover:bg-sf-orange hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {{ tweakerOptions[option] }}
                </button>
            </div>
        </div>
        <div v-else>
            <div class="animate-tw-pulse relative inline-flex group">
                <div
                    class="absolute transition-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"
                ></div>
                <button
                    :disabled="isGenerating"
                    @click="handleGenerateSummary"
                    class="hover:text-lg hover:px-6 hover:py-3 uppercase rounded-full relative inline-flex items-center justify-center px-4 py-2 text-sf-orange transition-all duration-200 bg-white border border-sf-orange font-pj rounded-xl focus:font-extrabold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sf-orange"
                >
                    <div class="flex" v-if="isGenerating">
                        <ArrowPathIcon class="animate-spin h-6 w-6 mr-2"></ArrowPathIcon>
                        Generating
                    </div>
                    <div class="flex" v-else>
                        <SparklesIcon class="h-6 w-6 mr-2"></SparklesIcon>
                        Generate
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import { useEventBus } from "@vueuse/core";
import { ArrowPathIcon, SparklesIcon } from "@heroicons/vue/20/solid";
import { useFormDataStore } from "@/stores/FormDataStore";

const props = defineProps({
    isGenerating: {
        type: Boolean,
        default: false,
    },
    handleGenerateSummary: Function,
    handleTweakSummary: Function,
    summaryKey: String,
    defaultShowTweakers: {
        type: Boolean,
        default: false,
    },
});

const store = useFormDataStore();
const errorBus = useEventBus("error");
const axios = inject("axios");

const tweakerOptions = ref({});

const loadTweakerOptions = async () => {
    axios
        .get("/api/summaries/tweakOptions")
        .then((response) => {
            tweakerOptions.value = response.data;
        })
        .catch((error) => {
            console.error("Error fetching tweaker options:", error);
            errorBus.emit("Error loading tweaker options. Please reload the page.");
        });
};

onMounted(() => {
    loadTweakerOptions();
});
</script>
