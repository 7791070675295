<template>
    <SchemeflowMap
        mapKey="rail_stations_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'rail_stations', 'metro']"
    />
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { onMounted, onUnmounted, watch } from "vue";
import { legendItemFromConfig } from "@/maps/configIcons";

const store = useFormDataStore();

let unwatch;

onMounted(async () => {
    // Include rail stations and metro stops overlay layers
    store.maps.rail_stations_map.toggleOverlayLayer("rail_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("metro_stops", true);

    // Fetch station data when map moves
    store.maps.rail_stations_map.getMap().on("moveend", () => {
        store.maps.rail_stations_map.getMap().once("rendercomplete", store.fetchRailStations);
    });

    // Update legend based on whether stations or tram/metro stops are on map
    unwatch = watch(
        [
            () => store.formData.rail_stations,
            () => store.formData.tram_metro_stops,
            () => store.formData.rail_stations_map.styles.site_marker,
            () => store.formData.rail_stations_map.styles.site_boundary,
        ],
        ([newStations, newStops]) => {
            store.maps.rail_stations_map.legend_config.rail_stations = [
                ...(newStations?.length > 0
                    ? [legendItemFromConfig({ item: "rail_station", mapKey: "rail_stations_map" })]
                    : []),
            ];

            store.maps.rail_stations_map.legend_config.metro = [
                ...(newStops?.length > 0
                    ? [legendItemFromConfig({ item: "metro_station", mapKey: "rail_stations_map" })]
                    : []),
            ];
        },
        { immediate: true }
    );
    setTimeout(() => {
        store.maps.rail_stations_map
            .getMap()
            .once("rendercomplete", () => (store.mapsReadyForExport.rail_stations_map = true));
        store.maps.rail_stations_map.getMap().renderSync();
    }, 5000);
});

onUnmounted(() => {
    unwatch();
    store.mapsReadyForExport.rail_stations_map = false;
});
</script>
