<template>
    <FormSection>
        <template #title>Cover Page</template>
        <template #inputs>
            <div class="mb-4">
                <label for="name_of_development" class="block text-sm font-medium text-gray-700">
                    Name of Development:
                </label>
                <input
                    v-model="store.formData.name_of_development"
                    type="text"
                    id="name_of_development"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="client_name" class="block text-sm font-medium text-gray-700">Client Name</label>
                <input
                    v-model="store.formData.client_name"
                    type="text"
                    id="client_name"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="planning_applicant" class="block text-sm font-medium text-gray-700">
                    Planning Applicant
                </label>
                <input
                    v-model="store.formData.planning_applicant"
                    type="text"
                    id="planning_applicant"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="document_type" class="block mb-2 text-sm font-medium text-gray-900">Document Type</label>
                <input
                    v-model="store.formData.document_type"
                    type="text"
                    id="document_type"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="company" class="block text-sm font-medium text-gray-700">Your Company</label>
                <input
                    v-model="store.formData.company"
                    type="text"
                    id="company"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="project_number" class="block text-sm font-medium text-gray-700">Project Number</label>
                <input
                    v-model="store.formData.project_number"
                    type="text"
                    id="project_number"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="first_name" class="block text-sm font-medium text-gray-700">Your First Name</label>
                <input
                    v-model="store.formData.first_name"
                    type="text"
                    id="first_name"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>

            <div class="mb-4">
                <label for="last_name" class="block text-sm font-medium text-gray-700">Your Last Name</label>
                <input
                    v-model="store.formData.last_name"
                    type="text"
                    id="last_name"
                    class="mt-1 p-2 w-full border rounded-md"
                />
            </div>
        </template>
    </FormSection>
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
const store = useFormDataStore();
</script>
